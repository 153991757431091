import React, { useEffect, useState } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { LuExpand } from 'react-icons/lu'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import ImageView from 'components/ImageView'
import { FaFileDownload } from 'react-icons/fa'
import { LoadingComponent } from 'components/LoadingComponent'
import { NoData } from 'components/NoData'
import { useMutation } from 'react-query'
import { getExportResponses } from 'services/request/Exports'

function CompaniesChat({ selectedQuestion, selectedEvent, questionResponses, isLoading }) {
    const [selectedResponse, setSelectedResponse] = useState(null)
    const [selectedImage, setSelectedImage] = useState()

    const { mutate: ExportData } = useMutation(['GetExportApi'], (data) => {
        getExportResponses(data)
    })

    const exportAllData = (event, question) => {
        const data = {
            event_id: event.event_id,
            question_id: question.id,
            type: question.type,
            event: event,
            question: question,
        }
        ExportData(data)
    }

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <div className=" w-2/4 bg-white min-h-full h-[100%] rounded-md custom-scroll overflow-y-auto border-l border-opacity-50 border-gray-400 grow">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

            <div className="bg-white sm:w-[100%] max-w-[680px] min-h-full  h-fit ">
                <div className="w-full flex flex-col gap-2 px-5 py-3">
                    <div className="w-full h-fit border-b border-main border-opacity-40 pb-5">
                        <div className="w-full text-xl flex flex-col text-main font-bold border-l-4 border-main pl-4">
                            <div className="w-full ">
                                <p className="w-full text-left text-2xl font-bold">
                                    {selectedQuestion[0] === 0
                                        ? 'Select an Event Question'
                                        : selectedEvent?.event_name}
                                </p>

                                <div className="text-lg">
                                    <p>Responses to Question: </p>
                                    <span>{selectedQuestion.question}</span>
                                </div>
                            </div>
                            <div className="w-full text-sm flex gap-2 items-end text-main font-bold cursor-pointer  mt-2">
                                <span
                                    onClick={() => exportAllData(selectedEvent, selectedQuestion)}
                                    className="flex justify-center items-center hover:text-xl"
                                >
                                    <FaFileDownload />
                                    <p className="text-sm">Download</p>
                                </span>
                                {/* <span
                                    onClick={() => setIsDownload(true)}
                                    className="flex justify-center items-center hover:text-xl"
                                >
                                    <FaFileDownload />
                                    <p className="text-sm">Download PDF</p>
                                </span> */}
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <span className="">
                            <LoadingComponent title={'Responses'} />
                        </span>
                    ) : questionResponses.length == 0 ? (
                        <NoData />
                    ) : (
                        questionResponses &&
                        questionResponses.map((response, responseIndex) => (
                            <div
                                // onClick={() => handleQuestionSelect(response)}
                                className="cursor-pointer text-sm  border-main p-2"
                                key={responseIndex}
                            >
                                <span className="text-sm font-bold   ">
                                    {response?.user?.fullname}
                                </span>

                                <div className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-start text-left bg-gray-300  bg-opacity-30 py-2 pl-2 pr-4 rounded-bl-md rounded-r-md shadow-sm">
                                    {/* <p className="text-sm"> */}
                                    {/* <p
                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                response.answer
                                            )}
                                        /> */}

                                    <p
                                        className="text-sm w-full text-justify break-words"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {response.answer &&
                                            makeUrlsClickable(response.answer).map(
                                                (part, index) => (
                                                    <React.Fragment key={index}>
                                                        {part}
                                                    </React.Fragment>
                                                )
                                            )}
                                    </p>

                                    {response?.attachments && response?.attachments.length > 0 && (
                                        <React.Fragment>
                                            {response?.attachments.filter(
                                                (attachment) => attachment.type === 'image'
                                            ).length > 1 ? (
                                                <div className="grid grid-cols-4 gap-1">
                                                    {response?.attachments
                                                        .filter(
                                                            (attachment) =>
                                                                attachment.type === 'image'
                                                        )
                                                        .map((attachment) => (
                                                            <div
                                                                className="flex items-start justify-start w-full"
                                                                key={attachment.id}
                                                            >
                                                                <img
                                                                    className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                    src={attachment.filename}
                                                                    alt="Attachment"
                                                                    onClick={() =>
                                                                        setSelectedImage(
                                                                            attachment.filename
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        ))}
                                                </div>
                                            ) : (
                                                response?.attachments
                                                    .filter(
                                                        (attachment) => attachment.type === 'image'
                                                    )
                                                    .map((attachment) => (
                                                        <div
                                                            className="flex items-start justify-start w-full"
                                                            key={attachment.id}
                                                        >
                                                            <img
                                                                className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                src={attachment.filename}
                                                                alt="Attachment"
                                                                onClick={() =>
                                                                    setSelectedImage(
                                                                        attachment.filename
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ))
                                            )}
                                            <div>
                                                {response?.attachments
                                                    .filter(
                                                        (attachment) => attachment.type !== 'image'
                                                    )
                                                    .map((attachment) => (
                                                        <div
                                                            className="flex items-start justify-start w-full"
                                                            key={attachment.id}
                                                        >
                                                            {attachment.type === 'file' ? (
                                                                <NavLink
                                                                    to={attachment.filename}
                                                                    target="__blank"
                                                                    className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                >
                                                                    <img
                                                                        className="w-8"
                                                                        src={PDFIcon}
                                                                        alt="TEXT_FILE"
                                                                    />
                                                                    <p className="text-left text-xs break-all truncate-multiline-2">
                                                                        {attachment.filename}
                                                                    </p>
                                                                </NavLink>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                            </div>
                                        </React.Fragment>
                                    )}
                                    {/* </p> */}
                                    <div className="w-full flex gap-4 justify-between">
                                        {response.type === 0 && (
                                            <div className="flex gap-2">
                                                <div className="flex">
                                                    <span className="text-main">
                                                        <BiSolidLike />
                                                    </span>
                                                    <span>{response.total_likes}</span>
                                                </div>
                                                <div className="flex">
                                                    <span className="text-grey">
                                                        <BiSolidDislike />
                                                    </span>
                                                    <span>{response.total_dislikes}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <span>{response.created_at}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full flex flex-col gap-2">
                                    <div className="px-10 flex gap-2">
                                        {response.responses?.length > 0 && (
                                            <div className="flex gap-2">
                                                <span className="rotate-90 text-base justify-center items-center ">
                                                    <MdOutlineSubdirectoryArrowLeft />
                                                </span>
                                                <span
                                                    onClick={() =>
                                                        setSelectedResponse(
                                                            selectedResponse === response
                                                                ? null
                                                                : response
                                                        )
                                                    }
                                                    className="cursor-pointer pt-2"
                                                >
                                                    {selectedResponse === response
                                                        ? 'Hide'
                                                        : 'Show'}{' '}
                                                    Replies
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {selectedResponse === response &&
                                        response.responses?.length > 0 && (
                                            <React.Fragment>
                                                <div className="flex flex-col w-full ml-5 px-6 animate_fade_up mb-2">
                                                    {response.responses.map((responses) => (
                                                        <div>
                                                            <span className="text-xs font-bold">
                                                                {responses?.user?.fullname}
                                                            </span>
                                                            <div
                                                                className=" flex flex-col w-[100%] gap-3 bg-main  bg-opacity-30 py-2 p-4 my-1 rounded-l-md rounded-tr-md shadow-sm"
                                                                key={responses.id}
                                                            >
                                                                <p
                                                                    className="text-sm text-justify break-words"
                                                                    style={{
                                                                        whiteSpace: 'pre-line',
                                                                    }}
                                                                >
                                                                    {responses.answer &&
                                                                        makeUrlsClickable(
                                                                            responses.answer
                                                                        ).map((part, index) => (
                                                                            <React.Fragment
                                                                                key={index}
                                                                            >
                                                                                {part}
                                                                            </React.Fragment>
                                                                        ))}
                                                                </p>

                                                                {responses?.attachments &&
                                                                    responses?.attachments.length >
                                                                        0 && (
                                                                        <React.Fragment>
                                                                            {responses?.attachments.filter(
                                                                                (attachment) =>
                                                                                    attachment.type ===
                                                                                    'image'
                                                                            ).length > 1 ? (
                                                                                <div className="grid grid-cols-4 gap-1">
                                                                                    {responses?.attachments
                                                                                        .filter(
                                                                                            (
                                                                                                attachment
                                                                                            ) =>
                                                                                                attachment.type ===
                                                                                                'image'
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                attachment
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="flex items-start justify-start w-full"
                                                                                                    key={
                                                                                                        attachment.id
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                                                        src={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        alt="Attachment"
                                                                                                        onClick={() =>
                                                                                                            setSelectedImage(
                                                                                                                attachment.filename
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                </div>
                                                                            ) : (
                                                                                responses?.attachments
                                                                                    .filter(
                                                                                        (
                                                                                            attachment
                                                                                        ) =>
                                                                                            attachment.type ===
                                                                                            'image'
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            attachment
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex items-start justify-start w-full"
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                                    src={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    alt="Attachment"
                                                                                                    onClick={() =>
                                                                                                        setSelectedImage(
                                                                                                            attachment.filename
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                            )}
                                                                            <div>
                                                                                {responses?.attachments
                                                                                    .filter(
                                                                                        (
                                                                                            attachment
                                                                                        ) =>
                                                                                            attachment.type !==
                                                                                            'image'
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            attachment
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex items-start justify-start w-full"
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                            >
                                                                                                {attachment.type ===
                                                                                                'file' ? (
                                                                                                    <NavLink
                                                                                                        to={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        target="__blank"
                                                                                                        className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                    >
                                                                                                        <img
                                                                                                            className="w-8"
                                                                                                            src={
                                                                                                                PDFIcon
                                                                                                            }
                                                                                                            alt="TEXT_FILE"
                                                                                                        />
                                                                                                        <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                            {
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                        </p>
                                                                                                    </NavLink>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )}

                                                                <div className="w-full flex gap-4 justify-between">
                                                                    {response.type === 0 && (
                                                                        <div className="flex gap-2">
                                                                            <div className="flex">
                                                                                <span className="text-main">
                                                                                    <BiSolidLike />
                                                                                </span>
                                                                                <span>
                                                                                    {
                                                                                        responses.total_likes
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="flex">
                                                                                <span className="text-grey">
                                                                                    <BiSolidDislike />
                                                                                </span>
                                                                                <span>
                                                                                    {
                                                                                        responses.total_dislikes
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div>
                                                                        <span>
                                                                            {
                                                                                responses.user
                                                                                    .created_at
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}

export default CompaniesChat

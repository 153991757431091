import {
    AnalyticsCompanyDetails,
    AnalyticsDetails,
    AnalyticsEventsDetails,
    AnalyticsReactionsDetails,
} from 'components/AnalyticsDetails/index'
import { useAtomValue } from 'jotai'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Chart } from 'react-google-charts'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { Link, NavLink } from 'react-router-dom'
import { getAnalyticsApi } from 'services/request/Dashboard'
import { authAtom } from 'store/authAtom'
import PDFIcon from '../../assets/pdf.png'
import { LoadingComponent } from 'components/LoadingComponent'
import { NoData } from 'components/NoData'
import { Divider } from '@mui/material'
import { AiFillLike } from 'react-icons/ai'
import { MdOutlineFileDownload } from 'react-icons/md'
import MainEventsExport, { QuestMostRepsExport } from 'components/Exports/ExportQuestAnalytics'
import { UserMostExport } from 'components/Exports/ExportUserAnalytics'
import ReactExport from 'components/Exports/ExportReactAnalytics'
import AnalyticsExport from 'components/Exports/ExportAnalytics'

export const ChartTab = ({ dataContainer }) => {
    const { user } = useAtomValue(authAtom)
    // const { data, isLoading } = useQuery(['getAnalyticsApi'], getAnalyticsApi)
    const { data, isLoading } = useQuery(['getAnalyticsApi', dataContainer.id], () =>
        getAnalyticsApi(dataContainer.id)
    )
    const [analyticsDownloadQuest, setAnalyticsDownloadQuest] = useState(false)
    const [analyticsDownloadUser, setAnalyticsDownloadUser] = useState(false)
    const [analyticsDownloadReact, setAnalyticsDownloadReact] = useState(false)
    const [analyticsDownload, setAnalyticsDownload] = useState(false)

    const { pie_chart, most_interacted } = useMemo(() => data ?? [], [data])

    const mostLiked = most_interacted?.most_liked
    const mostDisliked = most_interacted?.most_disliked

    const MostUserReplies = [['User', 'Responses']]
    if (pie_chart?.user_most) {
        pie_chart?.user_most.forEach((user) => {
            const userName = user?.fullname || ''
            const responses = user?.responses_count || 0
            MostUserReplies.push([userName, responses])
        })
    }

    const QuestionsWithMostRepliesData = [['KIQs', 'Questions']]
    // const QuestionsWithMostRepliesData = []

    const questionsMost = pie_chart?.questions_most

    if (questionsMost) {
        Object.keys(questionsMost).forEach((key) => {
            const type = key
            const questions = questionsMost[key]?.questions || []
            const questionsItem = questions.length

            QuestionsWithMostRepliesData.push([type, questionsItem])
        })
    }

    // const EvetsWithMostResponse = [['Event', 'Response Count']]
    // if (pie_chart?.events) {
    //     pie_chart?.events.forEach((event) => {
    //         const eventName = event.event_name
    //         const responseCount = event.responses ? event.responses?.length : 0
    //         EvetsWithMostResponse.push([eventName, responseCount])
    //     })
    // }

    const UserssWithMostResponse = {
        title: 'Users With Most Response',
        legend: {
            position: 'bottom', // or 'bottom', 'left', 'right', 'in', 'none'
            interactivity: false,
        },
    }
    const QuestionsWithMostReplies = {
        title: 'Questions With Most Replies',
        legend: {
            position: 'bottom', // or 'bottom', 'left', 'right', 'in', 'none'
            interactivity: false,
        },
        pieHole: 0.4,
        is3D: false,
    }
    // const EventsWithMostResponse = {
    //     title: 'Events With Most Response',
    //     legend: {
    //         position: 'bottom', // or 'bottom', 'left', 'right', 'in', 'none'
    //         interactivity: false,
    //     },
    // }

    // const areaData = [['Company', 'User Count', 'Events Count']]
    // const responseData = [['Company', 'Response Count']]

    // if (area_chart) {
    //     area_chart.company_users.forEach((company) => {
    //         const companyName = company.firstname
    //         const userCount = company.company ? company.company?.length : 0

    //         const companyData = {
    //             firstname: companyName,
    //             company: company.company || [],
    //             responses: 0,
    //             events: [],
    //         }
    //         // Find responses and events data for the current company
    //         const response = area_chart.responses.find((res) => res.firstname === companyName)
    //         if (response) {
    //             // Sum up the responses for each user within the company
    //             companyData.responses = response.company.reduce((count, user) => {
    //                 return count + (user.responses ? user.responses?.length : 0)
    //             }, 0)
    //         }

    //         const events = area_chart.events.find((event) => event.firstname === companyName)
    //         if (events) {
    //             companyData.events = events.events || []
    //         }

    //         areaData.push([
    //             companyData.firstname,
    //             userCount,
    //             // companyData.responses,
    //             companyData.events.length,
    //         ])
    //         responseData.push([companyData.firstname, companyData.responses])
    //     })
    // }
    // const areaOptions = {
    //     title: 'Company Performance by User Count & Event Counts',
    //     hAxis: { titleTextStyle: { color: '#333' } },
    //     vAxis: { minValue: 0 },
    //     chartArea: { width: '80%', height: '50%' },
    //     legend: {
    //         position: 'bottom', // or 'bottom', 'left', 'right', 'in', 'none'
    //         interactivity: false,
    //     },
    // }
    // const areaOptions2 = {
    //     title: 'Company Performance by Responses Count',
    //     hAxis: { titleTextStyle: { color: '#333' } },
    //     vAxis: { minValue: 0 },
    //     chartArea: { width: '80%', height: '50%' },
    //     legend: {
    //         position: 'bottom', // or 'bottom', 'left', 'right', 'in', 'none'
    //         interactivity: false,
    //     },
    // }

    const handleModal = () => {
        setAnalyticsModalResponse(true)
    }
    // const handleModalCompany = (data) => {
    //     setAnalyticsModalCompany(true)
    //     setAnalyticsData(data)
    // }
    const handleModalUsers = () => {
        setAnalyticsModalUsers(true)
    }
    const handleModalReactions = () => {
        setAnalyticsModalReactions(true)
    }

    const [analyticsModalResponse, setAnalyticsModalResponse] = useState(false)
    const [analyticsModalUsers, setAnalyticsModalUsers] = useState(false)
    const [analyticsModalReactions, setAnalyticsModalReactions] = useState(false)
    const [toggleFilter, setToggleFilter] = useState(false)
    const checkboxRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggleFilter(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <div className="bg-white h-full rounded-b-lg custom-scroll py-5 ">
            {analyticsModalResponse && (
                <AnalyticsDetails
                    data={pie_chart?.user_most}
                    onClose={() => setAnalyticsModalResponse(false)}
                />
            )}
            {/* {analyticsModalCompany && (
                <AnalyticsCompanyDetails
                    data={analyticsData}
                    onClose={() => setAnalyticsModalCompany(false)}
                />
            )} */}
            {analyticsModalUsers && (
                <AnalyticsEventsDetails
                    data={pie_chart.questions_most}
                    onClose={() => setAnalyticsModalUsers(false)}
                />
            )}
            {analyticsModalReactions && (
                <AnalyticsReactionsDetails
                    data={most_interacted}
                    onClose={() => setAnalyticsModalReactions(false)}
                />
            )}
            {analyticsDownloadUser && (
                <div className="w-[45%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <UserMostExport
                        data={pie_chart?.user_most}
                        onClose={() => setAnalyticsDownloadUser(false)}
                    />
                </div>
            )}
            {analyticsDownloadQuest && (
                <div className="w-[45%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <QuestMostRepsExport
                        onClose={() => setAnalyticsDownloadQuest(false)}
                        data={pie_chart.questions_most}
                    />
                </div>
            )}

            {analyticsDownloadReact && (
                <div className="w-[45%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <ReactExport
                        data={most_interacted}
                        onClose={() => setAnalyticsDownloadReact(false)}
                    />
                </div>
            )}
            {analyticsDownload && (
                <div className="w-[45%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <AnalyticsExport
                        data={pie_chart?.user_most}
                        dataQuest={pie_chart.questions_most}
                        dataReact={most_interacted}
                        onClose={() => setAnalyticsDownload(false)}
                    />
                </div>
            )}
            <button
                ref={checkboxRef}
                onClick={() => setToggleFilter(true)}
                className="text-xs relative justify-end w-full pr-7 text-main whitespace-nowrap flex items-center h-fit cursor-pointer sm:my-0"
            >
                PDF{' '}
                <span className="text-xl">
                    <MdOutlineFileDownload />
                </span>
                {toggleFilter && (
                    <div className="animate_fade_up z-10 mt-[6.5rem] -ml-2 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() => {
                                setAnalyticsDownloadUser(true)
                            }}
                        >
                            User With Most Response PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() => {
                                setAnalyticsDownloadQuest(true)
                            }}
                        >
                            Question With Most Replies PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() => {
                                setAnalyticsDownloadReact(true)
                            }}
                        >
                            Most Liked and Disliked Responses PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() => setAnalyticsDownload(true)}
                        >
                            Analytics PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                    </div>
                )}
            </button>

            <div className=" w-full h-full max-h-[70vh] overflow-y-auto flex flex-col px-4 pb-10">
                <div className="flex w-full h-fit gap-1">
                    {isLoading ? (
                        <span className="text-xs flex whitespace-normal text-center text border border-main rounded-md border-opacity-50 h-full px-10 w-1/3">
                            {' '}
                            <LoadingComponent title="Users With Most Response Data" />
                        </span>
                    ) : (
                        <span className="w-1/3 grow flex flex-col justify-center items-center select-none animate_fade_up">
                            {pie_chart?.user_most[0]?.responses_count == 0 ? (
                                <div>
                                    <p>There is no record in this section</p>
                                    <p className="text-sm font-semibold text-main">
                                        Chart : Users With Most Response
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <Chart
                                        chartType="PieChart"
                                        data={MostUserReplies}
                                        options={UserssWithMostResponse}
                                        width={'100%'}
                                        height={'300px'}
                                    />
                                    <button
                                        onClick={() => handleModal()}
                                        className="w-10/12 text-sm rounded-md py-2 text-white bg-main shadow-md cursor-pointer"
                                    >
                                        Expand
                                    </button>
                                </>
                            )}
                        </span>
                    )}

                    {isLoading ? (
                        <span className="text-xs flex whitespace-normal text-center text border border-main rounded-md border-opacity-50 h-full px-10 w-1/3">
                            {' '}
                            <LoadingComponent title="Events With Most Response Data" />
                        </span>
                    ) : (
                        <span className="w-1/3 grow flex flex-col justify-center items-center select-none animate_fade_up">
                            {Object.keys(pie_chart?.questions_most || {}).length == 0 ? (
                                <div>
                                    <p>There is no record in this section</p>
                                    <p className="text-sm font-semibold text-main">
                                        Chart : Questions With Most Replies
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <Chart
                                        chartType="PieChart"
                                        data={QuestionsWithMostRepliesData}
                                        options={QuestionsWithMostReplies}
                                        width={'100%'}
                                        height={'300px'}
                                    />
                                    <button
                                        onClick={() => handleModalUsers()}
                                        className="w-10/12 text-sm rounded-md py-2 text-white bg-main shadow-md cursor-pointer"
                                    >
                                        Expand
                                    </button>
                                </>
                            )}
                        </span>
                    )}
                </div>
                {/* {user.role === 'admin' ? (
                    <div className="flex w-full h-fit animate_fade_up items-center justify-center">
                        {isLoading ? (
                            <span className="text-xs flex whitespace-normal text-center text border border-main rounded-md border-opacity-50 h-full px-10 w-1/2 mr-4">
                                {' '}
                                <LoadingComponent title="Company Performance by User Count & Event Counts Data" />
                            </span>
                        ) : (
                            <span className="w-1/2">
                                <Chart
                                    chartType="AreaChart"
                                    width="100%"
                                    height="500px"
                                    data={areaData}
                                    options={areaOptions}
                                />
                            </span>
                        )}
                        <div className="bg-main h-[400px] w-[.8px]"></div>
                        {isLoading ? (
                            <span className="text-xs flex whitespace-normal text-center text border border-main rounded-md border-opacity-50 h-full px-10 w-1/2 ml-4">
                                {' '}
                                <LoadingComponent title="Company Performance by Responses Count Data" />
                            </span>
                        ) : (
                            <span className="w-1/2">
                                <Chart
                                    chartType="AreaChart"
                                    width="100%"
                                    height="500px"
                                    data={responseData}
                                    options={areaOptions2}
                                />
                            </span>
                        )}
                    </div>
                ) : null} */}

                {isLoading ? (
                    <span className="text-xs flex whitespace-normal text-center text border border-main rounded-md border-opacity-50 h-full px-10 w-full mt-4">
                        {' '}
                        <LoadingComponent title="Replies with Most Likes and Dislikes" />
                    </span>
                ) : (
                    <div className=" w-full  h-fit justify-evenly items-center gap-10 mt-5 flex flex-col">
                        <div className="h-64 w-full">
                            <p className="italic font-semibold text-lg text-main">
                                Most Liked Response
                            </p>
                            {!mostLiked?.length == 0 ? (
                                <div className="w-full h-full bg-main  shadow-md rounded-md flex">
                                    <div className="w-1/2 px-5 pt-3 gap-5 flex flex-col">
                                        <div className="w-full h-fit">
                                            <p className="text-xs font-semibold">
                                                Name : <span>{mostLiked[0]?.user?.fullname}</span>
                                                {' - '}
                                                <span>{mostLiked[0]?.created_at}</span>
                                            </p>

                                            <p className="text-xs font-semibold">
                                                Event : {mostLiked[0]?.event.event_name}
                                            </p>
                                        </div>
                                        {mostLiked[0]?.attachments?.length == 0 ? (
                                            <div className="w-full bg-[#e7e7e7] p-4 rounded-md shadow-md">
                                                <p className="text-xs text-main font-bold truncate-multiline w-full">
                                                    {mostLiked[0]?.user?.fullname}
                                                </p>
                                                <span>
                                                    <p
                                                        className="text-sm truncate-multiline"
                                                        style={{
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {/* {mostLiked[0]?.answer} */}
                                                        {mostLiked[0]?.answer &&
                                                            makeUrlsClickable(
                                                                mostLiked[0]?.answer
                                                            ).map((part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            ))}
                                                    </p>
                                                    {/* <Divider className="text-xs font-semibold text-darkgrey cursor-pointer hover:text-sm transition-all duration-200">
                                                        See more
                                                    </Divider> */}
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="w-full h-fit bg-[#e7e7e7] p-4 rounded-md shadow-md">
                                                <p className="text-xs text-main font-bold truncate-multiline w-full">
                                                    {mostLiked[0]?.user?.fullname}
                                                </p>
                                                <span className="flex w-full h-28 ">
                                                    {mostLiked[0]?.attachments[0]?.type ==
                                                    'image' ? (
                                                        <img
                                                            className="w-full h-full text-sm object-cover hover:object-contain transition-all duration-150"
                                                            alt={
                                                                mostLiked[0]?.attachments[0]
                                                                    ?.filename
                                                            }
                                                            src={
                                                                mostLiked[0]?.attachments[0]
                                                                    ?.filename
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="h-full py-5 flex text-xs">
                                                            <img className="h-full" src={PDFIcon} />
                                                            <Link
                                                                className="w-1/4 leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                to={
                                                                    mostLiked[0]?.attachments[0]
                                                                        ?.filename
                                                                }
                                                                target="__blank"
                                                            >
                                                                {
                                                                    mostLiked[0]?.attachments[0]
                                                                        ?.filename
                                                                }
                                                            </Link>
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-1/2  h-full  px-5 pt-3 flex flex-col bg-[#95ea8e] rounded-md">
                                        <div className="h-full px-2 overflow-y-auto ">
                                            <div className="text-sm border-b-2 font-bold border-[#44813e] border-opacity-30 flex justify-between sticky top-0 bg-[#95ea8e]">
                                                <p className=" text-darkgrey">Name</p>
                                                <p className=" text-darkgrey flex gap-2 items-center justify-center">
                                                    {' '}
                                                    <AiFillLike className="text-darkgrey " /> Like
                                                    Count
                                                </p>
                                            </div>
                                            {mostLiked &&
                                                mostLiked.map((liked) => (
                                                    <div className="text-sm border-b border-[#44813e] border-opacity-30 flex justify-between">
                                                        <p className="font-semibold text-darkgrey">
                                                            {liked?.user?.fullname}
                                                        </p>
                                                        <p className=" font-semibold text-darkgrey px-8">
                                                            {liked?.likes_count}
                                                        </p>
                                                    </div>
                                                ))}
                                        </div>
                                        {/* <Divider className="text-xs font-semibold text-[#44813e] cursor-pointer hover:text-sm transition-all duration-200 py-2">
                                            Show All Responses
                                        </Divider> */}
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex flex-col justify-center items-center bg-[#d7f7d4] rounded-md">
                                    <p>There is no record in this section</p>
                                    <p className="text-sm font-semibold text-main">
                                        Responses : Response With Most Likes
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="h-64 w-full">
                            <p className="italic font-semibold text-lg text-darkgrey">
                                Most Disliked Response
                            </p>
                            {!mostLiked?.length == 0 ? (
                                <div className="w-full min-h-[100%] h-fit bg-[#e7e7e7] shadow-md rounded-md flex">
                                    <div className="w-1/2 px-5 pt-3 gap-5 flex flex-col">
                                        <div className="w-full h-fit">
                                            <p className="text-xs font-semibold">
                                                Name :{' '}
                                                <span>{mostDisliked[0]?.user?.fullname}</span>
                                                {' - '}
                                                <span>{mostDisliked[0]?.created_at}</span>
                                            </p>

                                            <p className="text-xs font-semibold">
                                                Event : {mostDisliked[0]?.event?.event_name}
                                            </p>
                                        </div>
                                        {mostDisliked[0]?.attachments?.length == 0 ? (
                                            <div className="w-full bg-[#e7e7e7] p-4 rounded-md shadow-md">
                                                <p className="text-xs text-main font-bold truncate-multiline w-full">
                                                    {mostDisliked[0]?.user?.fullname}
                                                </p>
                                                <span>
                                                    <p
                                                        className="text-sm truncate-multiline"
                                                        style={{
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {/* {mostDisliked[0]?.answer} */}
                                                        {mostDisliked[0]?.answer &&
                                                            makeUrlsClickable(
                                                                mostDisliked[0]?.answer
                                                            ).map((part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            ))}
                                                    </p>
                                                    {/* <Divider className="text-xs font-semibold text-darkgrey cursor-pointer hover:text-sm transition-all duration-200">
                                                        See more
                                                    </Divider> */}
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="w-full h-fit bg-[#e7e7e7] p-4 rounded-md shadow-md">
                                                <p className="text-xs text-main font-bold truncate-multiline w-full">
                                                    {mostLiked[0]?.user?.fullname}
                                                </p>
                                                <span className="flex w-full h-28 ">
                                                    {mostDisliked[0]?.attachments[0]?.type ==
                                                    'image' ? (
                                                        <img
                                                            className="w-full h-full text-sm object-cover hover:object-contain transition-all duration-150"
                                                            alt={
                                                                mostDisliked[0]?.attachments[0]
                                                                    ?.filename
                                                            }
                                                            src={
                                                                mostDisliked[0]?.attachments[0]
                                                                    ?.filename
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="h-full py-5 flex text-xs">
                                                            <img className="h-full" src={PDFIcon} />
                                                            <Link
                                                                className="w-1/4 leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                to={
                                                                    mostDisliked[0]?.attachments[0]
                                                                        ?.filename
                                                                }
                                                                target="__blank"
                                                            >
                                                                {
                                                                    mostDisliked[0]?.attachments[0]
                                                                        ?.filename
                                                                }
                                                            </Link>
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-1/2 h-64  px-5 pt-3 flex flex-col bg-[#a9a9a9] rounded-md">
                                        <div className="h-full px-2 overflow-y-auto">
                                            <div className="text-sm border-b-2 font-bold border-[#44813e] border-opacity-30 flex justify-between sticky top-0 bg-[#a9a9a9]">
                                                <p className=" text-darkgrey">Name</p>
                                                <p className=" text-darkgrey flex gap-2 items-center justify-center">
                                                    {' '}
                                                    <AiFillLike className="text-darkgrey rotate-180" />{' '}
                                                    Dislike Count
                                                </p>
                                            </div>
                                            {mostDisliked &&
                                                mostDisliked.map((disliked) => (
                                                    <div className="text-sm border-b border-[#44813e] border-opacity-30 flex justify-between">
                                                        <p className="font-semibold text-darkgrey">
                                                            {disliked?.user.fullname}
                                                        </p>
                                                        <p className=" font-semibold text-darkgrey px-8">
                                                            {disliked?.dislikes_count}
                                                        </p>
                                                    </div>
                                                ))}
                                        </div>
                                        {/* <Divider className="text-xs font-semibold text-[#ffffff] cursor-pointer hover:text-sm transition-all duration-200 py-2">
                                            Show All Responses
                                        </Divider> */}
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex flex-col justify-center items-center bg-[#e6e6e6] rounded-md">
                                    <p>There is no record in this section</p>
                                    <p className="text-sm font-semibold text-main">
                                        Responses : Response With Most Dislikes
                                    </p>
                                </div>
                            )}
                        </div>
                        <button
                            onClick={() => handleModalReactions()}
                            className="w-full hover:bg-[#67a361] text-sm rounded-md py-2 text-white bg-main sm:mt-16 md:mt-10 shadow-md cursor-pointer transition-all durattion-150"
                        >
                            View More
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TbFileExport, TbTableExport } from 'react-icons/tb'
import DataTable from 'react-data-table-component'
import { Title } from '@tremor/react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import MainNav from 'components/MainNav'
import { useMutation, useQueries, useQuery } from 'react-query'
import { getPDFEventsApi, getSpacesEventsApi } from 'services/request/SpacesAndEvents'
import { LoadingComponent } from 'components/LoadingComponent'
import { getCompaniesQuestionApi, getCompaniesResponsesApi } from 'services/request/Companies'
import { getChatroomApi } from 'services/request/Dashboard'
import { BiChevronDown } from 'react-icons/bi'
import { FaFilter } from 'react-icons/fa'
import { Checkbox } from 'components/Checkbox'
import ImageView from 'components/ImageView'
import { IoCloseOutline } from 'react-icons/io5'
import SpacesEventsExport from 'components/Exports/SpacesEventsExport'
import {
    getEventDocxFile,
    getExportEventsList,
    getExportSelectedCompany,
} from 'services/request/Exports'
import ExportCompaniesKIQs from 'components/Exports/ExportCompaniesKIQs'
import EventDetailsModal from 'components/Buttons/EventsPageModal'
import EventsChat from 'components/ChatSelection/EventsChat'
import GeneralDiscussion from 'components/ChatSelection/GeneralDiscussion'
import { MdOutlineFileDownload } from 'react-icons/md'
import { DownloadProgress } from 'components/DownloadingComponent'
import ExportSpacesEvents from 'components/Exports/ExportSpacesEvents'

function SpacesEvents() {
    const { data, isLoading } = useQuery('getSpacesandEvents', getSpacesEventsApi)
    const { result } = useMemo(() => data ?? [], [data])
    const [dataCon, setDataCon] = useState(null)
    const [dataEvent, setDataEvent] = useState(null)
    const [attachments, setAttachments] = useState()
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState(result)
    const [eventQuestions, setEventQuestions] = useState([])
    const [selectedQuestion, setSelectedQuestion] = useState(0)
    const [kiqsSelected, setKiqsSelected] = useState('General Discussion')
    const [typeSelected, setTypeSelected] = useState()
    const [questionResponses, setQuestionResponses] = useState([])
    const [kiqsView, setKiqsView] = useState(0)
    const [toggle, setToggle] = useState(false)
    const checkboxRef = useRef(null)
    const [toDownload, setToDownload] = useState([])
    const [eventSearchable, setEventSearchable] = useState(true)
    const [groupSearchable, setGroupSearchable] = useState(true)
    const [selectedButton, setSelectedButton] = useState(3)
    const [selectedImage, setSelectedImage] = useState()
    const [toggleFilter, setToggleFilter] = useState(false)
    const [toggleFilterDocx, setToggleFilterDocx] = useState(false)
    const [dataExportEvent, setDataExportEvent] = useState()
    const [kiqsDownload, setKiqsDownload] = useState(false)
    const [dataEventType, setDataEventType] = useState()
    const [isDownload, setIsDownload] = useState(false)
    const [progress, setProgress] = useState()
    const [loading, setLoading] = useState(false)

    const closeModal = () => {
        setQuestionResponses([])
        setDataEvent(null)
        setSelectedQuestion(0)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggleFilter(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const customStyles = {
        rows: {
            style: {
                width: 'fit', // override the row height
            },
        },
        headCells: {
            style: {
                width: 'fit', // override the row height
            },
        },
        cells: {
            style: {
                width: 'fit', // override the row height
            },
        },
    }

    const calculateTotalUsers = (row) =>
        row.groups.reduce((acc, group) => acc + (group.users ? group.users.length : 0), 0)

    const handleSetDataEvent = (row) => {
        setDataEvent(row)
        // setKiqsSelected('')
    }
    const columns = [
        {
            name: 'Event Name',
            selector: (row) => (eventSearchable ? row.event_name : null),
            cell: (row) => (
                <button
                    className="cursor-pointer whitespace-nowrap hover:font-bold"
                    onClick={() => handleSetDataEvent(row)}
                >
                    {row.event_name}
                </button>
            ),
            // center: true,
            grow: 0,
            width: '300px',
            sortable: true,
        },
        {
            name: 'Agents',
            cell: (row) => {
                const totalUsers = calculateTotalUsers(row)
                return (
                    <button
                        disabled={totalUsers === 0}
                        className="cursor-pointer hover:font-bold bg-main bg-opacity-40 w-8 h-8 rounded-full justify-center text-center align-middle "
                        onClick={() => setDataCon(row)}
                    >
                        {totalUsers}
                    </button>
                )
            },
            center: true,
            grow: 0,
            width: '200px',
            sortable: true,
            sortFunction: (a, b, direction) => {
                const totalUsersA = calculateTotalUsers(a)
                const totalUsersB = calculateTotalUsers(b)
                return direction === 'asc' ? totalUsersA - totalUsersB : totalUsersB - totalUsersA
            },
        },
        {
            name: 'Responses',
            // selector: calculateTotalResponses,
            selector: (row) => row.chatroom_count + row.mykiqs_count + row.snapintel_count,
            cell: (row) => {
                const responsesCount = row.chatroom_count + row.mykiqs_count + row.snapintel_count
                return <span>{responsesCount}</span>
            },
            center: true,
            grow: 0,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Attachments',
            selector: (row) => row.attachments.length ?? 0,
            center: true,
            grow: 0,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Groups',
            selector: 'groups',
            cell: (row) => (
                <div className="flex gap-2 items-center my-2 sm:flex-col md:flex-col sm:justify-start sm:items-start">
                    {row.groups &&
                        row.groups.map((group, index) => (
                            <p
                                key={index}
                                className="px-2 py-1 border-[.6px] border-main bg-main bg-opacity-40 rounded-sm whitespace-nowrap "
                            >
                                {group.group_name}
                            </p>
                        ))}
                </div>
            ),
        },
    ]

    const handleSearchChange = (value) => {
        setSearch(value)

        const newFilteredData = result.filter((user) => {
            const isSearchMatch =
                value.trim() === '' ||
                columns.some((column) => {
                    if (typeof column.selector === 'function') {
                        const selectedValue = column.selector(user)
                        if (typeof selectedValue === 'string') {
                            return selectedValue.toLowerCase().includes(value.toLowerCase())
                        }
                    }
                    return false
                })

            const isGroupSearchMatch = groupSearchable
                ? value.trim() === '' ||
                  (user.groups &&
                      user.groups
                          .map((group) => group.group_name.toLowerCase())
                          .some((groupName) => groupName.includes(value.toLowerCase())))
                : null

            return isSearchMatch || isGroupSearchMatch
        })

        setFilteredData(newFilteredData)
    }

    const { mutate: mutateExportAll, isLoading: mutateIsloading } = useMutation(() =>
        getExportEventsList()
    )

    const exportAllData = () => {
        mutateExportAll()
    }

    const arrayRes = useQueries([
        {
            queryKey: ['getChatroomApi', kiqsView, dataEvent],
            queryFn: () => getChatroomApi(dataEvent.id),
            onSuccess: (data) => setAttachments(data.attachments),
            enabled: kiqsView === 0 || kiqsView === 3,
        },
        {
            queryKey: ['getCompaniesQuestionApi', 2, dataEvent, kiqsSelected],
            queryFn: () => getCompaniesQuestionApi(dataEvent.id),
            onSuccess: (data) => {
                setEventQuestions(data.result)
            },
            enabled: !!dataEvent,
        },
        {
            queryKey: ['getCompaniesResponsesApiHello', 3, selectedQuestion],
            queryFn: () =>
                getCompaniesResponsesApi(selectedQuestion.id, dataEvent.id, typeSelected),
            onSuccess: (data) => setQuestionResponses(data.result),
            // enabled: selectedQuestion.length !== 0,
        },
    ])

    const handleKIQSClicks = (kiqs, type) => {
        setKiqsSelected(kiqs)
        setTypeSelected(type)
        setSelectedButton(type)
        setQuestionResponses([])
        setSelectedQuestion([])
    }

    const handleQuestionSelect = (question) => {
        setSelectedQuestion(question)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggle(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const { mutate: PDFConvo } = useMutation((data) => getPDFEventsApi(data), {
        onSuccess: (res) => setDataExportEvent(res.result),
        onError: (res) => console.log(res),
    })

    const exportsPDFEventsKIQS = (event, type) => {
        PDFConvo({ event, type })
        setKiqsDownload(true)
    }

    const handleDownloadCSV = () => {
        setLoading(true)
        getExportSelectedCompany(
            '/api/v1/export_space_and_events',
            {
                event_id: dataEvent.id,
            },
            (data) => {
                setProgress(data.onDownloadProgress)
                setLoading(false)
            },
            `Spaces and Events KIQs ${dataEvent.event_name} .xlsx`
        )
    }

    const handleDownloadDocx = async (eventId, type, order) => {
        try {
            setLoading(true)

            await getEventDocxFile({
                event: eventId, // Use event ID passed from button
                intel_type: type, // Use intel type ('Snapintel')
                order_type: order, // Use order ('asc')
                event_name: dataEvent.event_name, // Assuming dataEvent is globally accessible
            })

            setLoading(false)
        } catch (e) {
            console.error('Error while downloading DOCX:', e)
            setLoading(false)
        }
    }

    return (
        <div className=" flex flex-col w-screen h-fit overflow-x-hidden">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

            {isDownload && (
                <div className="w-[50%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    {/* {console.log('de', dataEvent)} */}
                    <ExportSpacesEvents
                        data={dataEvent}
                        question={questionResponses}
                        onClose={() => setIsDownload(false)}
                    />
                </div>
            )}

            {kiqsDownload && (
                <div className="w-[50%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <ExportCompaniesKIQs
                        event={dataEvent}
                        data={dataExportEvent}
                        type={dataEventType}
                        onClose={() => setKiqsDownload(false)}
                    />
                </div>
            )}

            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full h-full flex gap-5 py-5 mb-10">
                <div className="w-full h-fit rounded-lg custom-scroll flex flex-col gap-4">
                    <div className="w-full px-5">
                        <MainNav />
                    </div>

                    <div className="w-full h-fit flex justify-between px-5 items-center sm:flex-col">
                        <p className="font-bold text-2xl sm:pb-3">Events</p>
                        <div className="sm:flex-col sm:w-full flex w-2/4 items-center justify-center h-full gap-2">
                            <div className="w-full h-fit flex  items-center justify-center">
                                <input
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    type="text"
                                    value={search}
                                    className="border border-gray-400 rounded-md placeholder:text-sm pl-3 p-1 flex grow bg-white bg-opacity-60 outline-none"
                                    placeholder="Search User..."
                                />

                                {search.length > 0 && (
                                    <div
                                        onClick={() => handleSearchChange('')}
                                        className="w-fit px-4 py-2 flex bg-main text-white font-bold scale-105 bg-opacity-60 rounded-r-lg border-main cursor-pointer"
                                    >
                                        <IoCloseOutline />
                                    </div>
                                )}
                            </div>
                            <span className="sm:flex-row flex gap-2">
                                <div
                                    className="flex flex-col justify-start items-end"
                                    ref={checkboxRef}
                                >
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        className="transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex border border-gray-400 rounded-md py-2 px-4 bg-white shadow-inner text-main bg-opacity-50"
                                    >
                                        {' '}
                                        <div className="flex">
                                            <FaFilter /> <BiChevronDown />
                                        </div>
                                    </div>
                                    {toggle && (
                                        <div className="animate_fade_up z-20 absolute mt-9 transition-all gap-2 duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col border border-gray-400 rounded-md h-fit pt-2 pb-4 px-4 bg-white shadow-inner text-main">
                                            <p className="text-sm">Filter by:</p>
                                            <Checkbox
                                                key="Events"
                                                name="Event"
                                                title="Event"
                                                isChecked={eventSearchable}
                                                setValue={() =>
                                                    setEventSearchable(!eventSearchable)
                                                }
                                            />
                                            <Checkbox
                                                key="Groups"
                                                name="Groups"
                                                title="Groups"
                                                isChecked={groupSearchable}
                                                setValue={() =>
                                                    setGroupSearchable(!groupSearchable)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>

                                <button
                                    className=" text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 text-white whitespace-nowrap"
                                    onClick={() => exportAllData()}
                                >
                                    {mutateIsloading ? (
                                        <div
                                            className="flex justify-center items-center gap-2"
                                            role="status"
                                        >
                                            <svg
                                                aria-hidden="true"
                                                className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#4f7e4a]"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="text-white text-xs">Loading...</span>
                                        </div>
                                    ) : (
                                        <span className="text-sm flex items-center hover:scale-105 transition-all duration-300 cursor-pointer">
                                            CSV{' '}
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </span>
                                    )}
                                </button>
                            </span>
                        </div>
                    </div>

                    <div className="w-full h-fit custom-header px-4 flex gap-0 sm:flex-col space-x-4 sm:space-x-0 sm:space-y-4 rounded-md overflow-hidden">
                        <div className=" w-full grow rounded-md overflow-hidden">
                            <DataTable
                                progressComponent={<LoadingComponent title={'Events'} />}
                                progressPending={isLoading}
                                columns={columns}
                                data={filteredData ? filteredData : result}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight="900px"
                                customStyles={customStyles}
                            />
                        </div>
                        {dataCon === null ? (
                            <div></div>
                        ) : (
                            <div className=" w-1/4 sm:w-full h-fit  custom-header bg-white p-4 rounded-lg shadow-md animate_fade_up pl-2">
                                <div className="w-full flex justify-between">
                                    <Title className="text-main font-bold ">
                                        {dataCon.event_name}
                                    </Title>
                                    <p
                                        className="text-xl items-center h-full text-main cursor-pointer"
                                        onClick={() => setDataCon(null)}
                                    >
                                        <AiOutlineCloseCircle />
                                    </p>
                                </div>
                                <div className="max-h-[400px] overflow-y-auto pl-2 ">
                                    <p className="font-bold">Members:</p>
                                    <ol>
                                        {dataCon.groups.map((group, groupIndex) => {
                                            return (
                                                <div key={groupIndex}>
                                                    <p className="font-semibold text-main">
                                                        &emsp; Group: {group.group_name}
                                                    </p>
                                                    {group.users &&
                                                        group.users.map((user, userIndex) => {
                                                            return (
                                                                <li key={userIndex}>
                                                                    {user.fullname}
                                                                </li>
                                                            )
                                                        })}
                                                </div>
                                            )
                                        })}
                                    </ol>
                                </div>
                            </div>
                        )}
                    </div>
                    {dataEvent && (
                        <div className=" fixed z-20 top-0 pt-14 w-screen h-screen flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
                            <p
                                className="text-2xl opacity-70 z-50 hover:opacity-100 items-center fixed top-16 right-5  text-slate-500 cursor-pointer md:text-2xl sm:text-3xl"
                                onClick={() => closeModal()}
                            >
                                <AiOutlineCloseCircle />
                            </p>
                            <div className="bg-white p-7 w-[100%] h-[100%] rounded-md flex space-y-7 border shadow-lg border-main flex-col overflow-y-auto overflow-x-hidden">
                                <div className="flex flex-col gap-2">
                                    <div className="w-full flex justify-between gap-4 items-center  ">
                                        <div
                                            className="text-main font-bold text-3xl sm:text-lg w-full  justify-between flex"
                                            ref={checkboxRef}
                                        >
                                            <p>{dataEvent?.event_name}</p>{' '}
                                            <div className="flex justify-start items-center sm:gap-1 gap-3 sm:flex-col ">
                                                <button
                                                    onClick={() =>
                                                        setToggleFilterDocx(!toggleFilterDocx)
                                                    }
                                                    className="text-xs flex items-center cursor-pointer"
                                                >
                                                    DOCX
                                                    <span className="text-xl">
                                                        <MdOutlineFileDownload />
                                                    </span>
                                                    {toggleFilterDocx && (
                                                        <div className=" z-50 mt-[7.5rem] -ml-9 absolute flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                                                                onClick={() => {
                                                                    handleDownloadDocx(
                                                                        dataEvent?.id,
                                                                        ['General Discussions'],
                                                                        'desc'
                                                                    )
                                                                }}
                                                            >
                                                                General Discussions Docx{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2  cursor-pointer"
                                                                onClick={() => {
                                                                    handleDownloadDocx(
                                                                        dataEvent?.id,
                                                                        ['Chatroom KIQs'],
                                                                        'desc'
                                                                    )
                                                                }}
                                                            >
                                                                Chatroom Docx{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                                                                onClick={() => {
                                                                    handleDownloadDocx(
                                                                        dataEvent?.id,
                                                                        ['My KIQs'],
                                                                        'desc'
                                                                    )
                                                                }}
                                                            >
                                                                KIQs Docx{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                                                                onClick={() => {
                                                                    handleDownloadDocx(
                                                                        dataEvent?.id,
                                                                        ['SnapIntel'],
                                                                        'desc'
                                                                    )
                                                                }}
                                                            >
                                                                SnapIntel Docx{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                                                                onClick={() =>
                                                                    handleDownloadDocx(
                                                                        dataEvent?.id,
                                                                        [
                                                                            'General Discussions',
                                                                            'Chatroom KIQs',
                                                                            'My KIQs',
                                                                            'SnapIntel',
                                                                        ],
                                                                        'desc'
                                                                    )
                                                                }
                                                            >
                                                                Event Docx{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )}
                                                </button>

                                                <button
                                                    onClick={() => setToggleFilter(!toggleFilter)}
                                                    className="text-xs relative whitespace-nowrap flex items-center h-fit cursor-pointer py-2 px-4 my-2 sm:my-0"
                                                >
                                                    PDF{' '}
                                                    <span className="text-xl">
                                                        <MdOutlineFileDownload />
                                                    </span>
                                                    {toggleFilter && (
                                                        <div className="animate_fade_up z-50 mt-[6.5rem] -ml-2 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                onClick={() => {
                                                                    exportsPDFEventsKIQS(
                                                                        dataEvent?.id,
                                                                        0
                                                                    )
                                                                    setDataEventType(0)
                                                                }}
                                                            >
                                                                Chatroom PDF{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                onClick={() => {
                                                                    exportsPDFEventsKIQS(
                                                                        dataEvent?.id,
                                                                        1
                                                                    )
                                                                    setDataEventType(1)
                                                                }}
                                                            >
                                                                KIQs PDF{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                onClick={() => {
                                                                    exportsPDFEventsKIQS(
                                                                        dataEvent?.id,
                                                                        2
                                                                    )
                                                                    setDataEventType(2)
                                                                }}
                                                            >
                                                                SnapIntel PDF{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                onClick={() => setIsDownload(true)}
                                                            >
                                                                Event PDF{' '}
                                                                <span className="text-lg">
                                                                    <MdOutlineFileDownload />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )}
                                                </button>
                                                {loading ? (
                                                    <DownloadProgress progress={progress} />
                                                ) : (
                                                    <button
                                                        onClick={() => handleDownloadCSV()}
                                                        className="text-xs flex items-center hover:scale-105 transition-all duration-300 cursor-pointer"
                                                    >
                                                        CSV{' '}
                                                        <span className="text-xl">
                                                            <MdOutlineFileDownload />
                                                        </span>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-4">
                                    <div className="w-full flex gap-2 sm:flex-col border-b-2 border-main border-opacity-30 pb-7">
                                        <button
                                            onClick={() =>
                                                handleKIQSClicks('General Discussion', 3)
                                            }
                                            className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm text-main border-main bg-white-25 ${
                                                selectedButton === 3 ? 'underline font-bold' : ''
                                            }`}
                                        >
                                            General Discussion
                                        </button>
                                        {eventQuestions.chatroom && (
                                            <button
                                                onClick={() => {
                                                    handleKIQSClicks('Chatroom', 0)
                                                    setSelectedQuestion(eventQuestions?.chatroom[0])
                                                }}
                                                className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm bg-[#7cc576] text-white hover:bg-[#87d680] ${
                                                    selectedButton === 0
                                                        ? 'underline font-bold'
                                                        : ''
                                                }`}
                                            >
                                                Chatroom KIQs
                                            </button>
                                        )}

                                        {eventQuestions.mykiqs && (
                                            <button
                                                onClick={() => {
                                                    handleKIQSClicks('My KIQS', 1)
                                                    setSelectedQuestion(eventQuestions?.mykiqs[0])
                                                }}
                                                className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm bg-[#333333] text-white hover:bg-[#484848] ${
                                                    selectedButton === 1
                                                        ? 'underline font-bold'
                                                        : ''
                                                }`}
                                            >
                                                My KIQs
                                            </button>
                                        )}

                                        {eventQuestions.snapintel && (
                                            <button
                                                onClick={() => {
                                                    handleKIQSClicks('SnapIntel', 2)
                                                    setSelectedQuestion(
                                                        eventQuestions?.snapintel[0]
                                                    )
                                                }}
                                                className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm bg-main bg-opacity-25 text-main hover:bg-[#e0f7de] ${
                                                    selectedButton === 2
                                                        ? 'underline font-bold'
                                                        : ''
                                                }`}
                                            >
                                                SnapIntel
                                            </button>
                                        )}
                                    </div>

                                    {kiqsSelected === 'General Discussion' ? (
                                        // <div className="animate_fade_up custom-scroll h-fit custom-pagination mt-3 w-full border  border-main/70 rounded-md block z-10">
                                        <div>
                                            <GeneralDiscussion
                                                dataContainer={arrayRes[0]?.data}
                                                isLoading={arrayRes[0]?.isLoading}
                                                className=" border  border-main/70"
                                            />
                                        </div>
                                    ) : (
                                        // </div>
                                        <EventsChat
                                            kiqsSelected={kiqsSelected}
                                            eventQuestions={eventQuestions}
                                            arrayRes={arrayRes}
                                            handleQuestionSelect={handleQuestionSelect}
                                            selectedQuestion={selectedQuestion}
                                            questionResponses={questionResponses}
                                        />
                                    )}
                                </div>
                                <div className="font-semibold text-gray-600 whitespace-normal text-justify p-2  border-t-2 border-main border-opacity-30">
                                    {dataEvent.description}
                                </div>
                                <div className="w-full flex sm:flex-col">
                                    <div className="w-1/2 sm:flex-col md:flex-col pb-3 border-main border-opacity-30">
                                        <p className="font-bold">Agents:</p>
                                        <ol className="flex flex-row space-x-32 sm:flex-col md:flex-col sm:space-x-0 md:space-x-0">
                                            {dataEvent.groups.map((group, groupIndex) => {
                                                return (
                                                    <div key={groupIndex}>
                                                        <p className="font-semibold text-main space-x-9 md:flex-col sm:flex-col whitespace-nowrap">
                                                            Group: {group.group_name}
                                                        </p>
                                                        {group.users &&
                                                            group.users.map((user, userIndex) => {
                                                                return <li>{user.fullname}</li>
                                                            })}
                                                    </div>
                                                )
                                            })}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SpacesEvents

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaWindowMinimize } from 'react-icons/fa'
import PDFIcon from '../../assets/pdf.png'
import { Link, NavLink } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'
import ImageView from 'components/ImageView'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'

export const ReactExport = ({ data, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        // Attach the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress)

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])
    const [selectedImage, setSelectedImage] = useState()

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`Export Most React Responses - ${data?.event_name}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                {selectedImage != null && (
                    <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                )}
                <div className="w-full bg-white rounded-md items-center justify-center h-full ">
                    <span className="w-full p-3 justify-center text-center h-fit text-main flex font-bold mt-2">
                        <p className="text-lg">Most Liked and Disliked Responses</p>
                    </span>
                    <div className="w-full h-5/6  flex gap-2 px-4 pb-5 flex-col">
                        <div className="w-full h-full rounded-md border border-opacity-50 ">
                            <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-main border-b border-opacity-50">
                                {' '}
                                {/* <span className="-scale-x-100 ">
                                    <BiSolidLike />{' '}
                                </span> */}
                                <svg
                                    stroke="currentColor"
                                    fill="#7cc576"
                                    stroke-width="0"
                                    viewBox="0 0 1024 1024"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311h-.3v428h472.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM112 528v364c0 17.7 14.3 32 32 32h65V496h-65c-17.7 0-32 14.3-32 32z"></path>
                                </svg>
                                Most Likes
                            </div>
                            <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                {data?.most_liked &&
                                    data?.most_liked.map((like) => (
                                        <div
                                            className="flex flex-col w-full items-end justify-end"
                                            key={like.id}
                                        >
                                            <span className="text-xs font-bold mb-2">
                                                {like?.user?.fullname}
                                            </span>
                                            <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                {/* {like.answer} */}
                                                <p
                                                    className="text-sm text-justify break-words"
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {like.answer &&
                                                        makeUrlsClickable(like.answer).map(
                                                            (part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </p>
                                                {like.attachments && (
                                                    <>
                                                        {like.attachments.filter(
                                                            (attach) => attach.type === 'image'
                                                        ).length > 0 && (
                                                            <div className="grid grid-cols-4 gap-2">
                                                                {like.attachments
                                                                    .filter(
                                                                        (attach) =>
                                                                            attach.type === 'image'
                                                                    )
                                                                    .map((attach) => (
                                                                        <div
                                                                            key={attach.id}
                                                                            className="overflow-hidden"
                                                                        >
                                                                            <img
                                                                                alt={
                                                                                    attach.filename
                                                                                }
                                                                                src={
                                                                                    attach.filename
                                                                                }
                                                                                className="object-cover w-full h-32"
                                                                            />
                                                                            <Link
                                                                                to={attach.filename}
                                                                                target="_blank"
                                                                                className="flex mt-1"
                                                                            >
                                                                                <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                    Click to view
                                                                                </p>
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}

                                                        {like.attachments.filter(
                                                            (attach) => attach.type !== 'image'
                                                        ).length > 0 && (
                                                            <div>
                                                                {like.attachments
                                                                    .filter(
                                                                        (attach) =>
                                                                            attach.type !== 'image'
                                                                    )
                                                                    .map((attach) => (
                                                                        <div
                                                                            className="h-28 w-full py-5 flex break-all text-xs"
                                                                            key={attach.id}
                                                                        >
                                                                            <img
                                                                                alt="PDF Icon"
                                                                                className="h-full w-fit"
                                                                                src={PDFIcon}
                                                                            />
                                                                            <div className="w-full">
                                                                                <p className="font-bold text-darkgrey">
                                                                                    Filename:
                                                                                </p>
                                                                                <Link
                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                >
                                                                                    {
                                                                                        attach.filename
                                                                                    }
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                <div className="w-full flex gap-4 justify-between">
                                                    {like.type === 0 && (
                                                        <div className="flex gap-2">
                                                            <div className="flex">
                                                                {/* <span className="text-main">
                                                                    <BiSolidLike />
                                                                </span> */}
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="#7cc576"
                                                                    stroke-width="0"
                                                                    viewBox="0 0 1024 1024"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311h-.3v428h472.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM112 528v364c0 17.7 14.3 32 32 32h65V496h-65c-17.7 0-32 14.3-32 32z"></path>
                                                                </svg>
                                                                <span>{like.likes_count}</span>
                                                            </div>
                                                            <div className="flex">
                                                                <span className="text-grey">
                                                                    <BiSolidDislike />
                                                                </span>
                                                                <span>{like.dislikes_count}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <span>{like.created_at}</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="w-full h-full rounded-md mb-4 border border-opacity-50 ">
                            <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-darkgrey border-b border-opacity-50">
                                {' '}
                                <span className="-scale-x-100 ">
                                    <BiSolidDislike />{' '}
                                </span>
                                Most Dislikes
                            </div>
                            <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                {data?.most_disliked &&
                                    data?.most_disliked.map((dislike) => (
                                        <div
                                            className="flex flex-col w-full items-end justify-end"
                                            key={dislike.id}
                                        >
                                            <span className="text-xs font-bold mb-2">
                                                {dislike?.user?.fullname}
                                            </span>
                                            <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                {/* {dislike.answer} */}
                                                <p
                                                    className="text-sm text-justify break-words"
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {dislike.answer &&
                                                        makeUrlsClickable(dislike.answer).map(
                                                            (part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </p>
                                                {dislike.attachments && (
                                                    <>
                                                        {dislike.attachments.filter(
                                                            (attach) => attach.type === 'image'
                                                        ).length > 0 && (
                                                            <div className="grid grid-cols-4 gap-2">
                                                                {dislike.attachments
                                                                    .filter(
                                                                        (attach) =>
                                                                            attach.type === 'image'
                                                                    )
                                                                    .map((attach) => (
                                                                        <div
                                                                            key={attach.id}
                                                                            className="overflow-hidden"
                                                                        >
                                                                            <img
                                                                                alt={
                                                                                    attach.filename
                                                                                }
                                                                                src={
                                                                                    attach.filename
                                                                                }
                                                                                className="object-cover w-full h-32"
                                                                            />
                                                                            <Link
                                                                                to={attach.filename}
                                                                                target="_blank"
                                                                                className="flex mt-1"
                                                                            >
                                                                                <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                    Click to view
                                                                                </p>
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}

                                                        {dislike.attachments.filter(
                                                            (attach) => attach.type !== 'image'
                                                        ).length > 0 && (
                                                            <div>
                                                                {dislike.attachments
                                                                    .filter(
                                                                        (attach) =>
                                                                            attach.type !== 'image'
                                                                    )
                                                                    .map((attach) => (
                                                                        <div
                                                                            className="h-28 w-full py-5 flex break-all text-xs"
                                                                            key={attach.id}
                                                                        >
                                                                            <img
                                                                                alt="PDF Icon"
                                                                                className="h-full w-fit"
                                                                                src={PDFIcon}
                                                                            />
                                                                            <div className="w-full">
                                                                                <p className="font-bold text-darkgrey">
                                                                                    Filename:
                                                                                </p>
                                                                                <Link
                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                >
                                                                                    {
                                                                                        attach.filename
                                                                                    }
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                <div className="w-full flex gap-4 justify-between">
                                                    {dislike.type === 0 && (
                                                        <div className="flex gap-2">
                                                            <div className="flex">
                                                                {/* <span className="text-main">
                                                                    <BiSolidLike />
                                                                </span> */}
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="#7cc576"
                                                                    stroke-width="0"
                                                                    viewBox="0 0 1024 1024"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311h-.3v428h472.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM112 528v364c0 17.7 14.3 32 32 32h65V496h-65c-17.7 0-32 14.3-32 32z"></path>
                                                                </svg>
                                                                <span>{dislike.likes_count}</span>
                                                            </div>
                                                            <div className="flex">
                                                                <span className="text-grey">
                                                                    <BiSolidDislike />
                                                                </span>
                                                                <span>
                                                                    {dislike.dislikes_count}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <span>{dislike.created_at}</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default ReactExport

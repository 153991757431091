import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaFile } from 'react-icons/fa'
import NexusLogo from '../../assets/NexusBrain.png'
import PDFIcon from '../../assets/pdf.png'
import { Link } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'
import { LoadingComponent } from 'components/LoadingComponent'
import Chart from 'react-google-charts'
import ImageView from 'components/ImageView'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { GoFile } from 'react-icons/go'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'

export const QuestMostRepsExport = ({ data, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }
    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         if (event.key === 'Escape') {
    //             onClose()
    //         }
    //     }
    //     window.addEventListener('keydown', handleKeyPress)

    //     return () => {
    //         window.removeEventListener('keydown', handleKeyPress)
    //     }
    // }, [])
    const [selectedImage, setSelectedImage] = useState()

    const [dataContainer, setDataContainer] = useState(data)
    const [itemHolder, setItemHolder] = useState([])
    const [selectedItem, setSelectedItem] = useState()
    const [kiqsType, setKiqsType] = useState(0)

    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline break-all font-bold" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }
    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                // fileName={`Export Spaces and Events - ${data?.event_name}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                <div className="w-full flex justify-center flex-col gap-2 p-4 mb-7 ">
                    {selectedImage != null && (
                        <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                    )}
                    <div className="w-full h-fit flex items-center justify-center pt-1">
                        <p className="text-main font-semibold text-lg italic">
                            Questions with Most Replies
                        </p>
                    </div>

                    <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                        <span className="flex">CHATROOM </span>
                    </p>
                    {dataContainer?.chatroom?.questions &&
                        dataContainer?.chatroom?.questions.map((chat_questions, index) => (
                            <>
                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my- w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    {chat_questions?.question}
                                </p>
                                {chat_questions && chat_questions?.responses?.length > 0 && (
                                    <div className="flex flex-col w-full px-6 gap-2">
                                        {chat_questions?.responses.map((response) => (
                                            <>
                                                <p className="  w-[65%] self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                    <span className="flex gap-2 mb-2 items-start">
                                                        <p className="text-main font-bold">
                                                            {response?.user?.fullname}
                                                        </p>
                                                    </span>
                                                    <p
                                                        className="whitespace-break-spaces items-end justify-end"
                                                        dangerouslySetInnerHTML={makeUrlsClickable(
                                                            response?.answer
                                                        )}
                                                    />

                                                    {response.attachments && (
                                                        <>
                                                            {response.attachments.filter(
                                                                (attach) => attach.type === 'image'
                                                            ).length > 0 && (
                                                                <div className="grid grid-cols-3 gap-2">
                                                                    {response.attachments
                                                                        .filter(
                                                                            (attach) =>
                                                                                attach.type ===
                                                                                'image'
                                                                        )
                                                                        .map((attach) => (
                                                                            <div
                                                                                key={attach.id}
                                                                                className="overflow-hidden"
                                                                            >
                                                                                <img
                                                                                    alt={
                                                                                        attach.filename
                                                                                    }
                                                                                    src={
                                                                                        attach.filename
                                                                                    }
                                                                                    className="object-cover w-full h-32"
                                                                                />
                                                                                <Link
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="flex mt-1"
                                                                                >
                                                                                    <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                        Click to
                                                                                        view
                                                                                    </p>
                                                                                </Link>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}

                                                            {response.attachments.filter(
                                                                (attach) => attach.type !== 'image'
                                                            ).length > 0 && (
                                                                <div>
                                                                    {response.attachments
                                                                        .filter(
                                                                            (attach) =>
                                                                                attach.type !==
                                                                                'image'
                                                                        )
                                                                        .map((attach) => (
                                                                            <div
                                                                                className="h-28 w-full py-5 flex break-all text-xs"
                                                                                key={attach.id}
                                                                            >
                                                                                <img
                                                                                    alt="PDF Icon"
                                                                                    className="h-full w-fit"
                                                                                    src={PDFIcon}
                                                                                />
                                                                                <div className="w-full">
                                                                                    <p className="font-bold text-darkgrey">
                                                                                        Filename:
                                                                                    </p>
                                                                                    <Link
                                                                                        className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="__blank"
                                                                                    >
                                                                                        {
                                                                                            attach.filename
                                                                                        }
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </p>
                                                {response && response?.responses?.length > 0 && (
                                                    <>
                                                        <div className="flex gap-2 self-end justify-end items-center mt-1 w-full mb-1">
                                                            {' '}
                                                            <div
                                                                style={{
                                                                    color: '#777777',
                                                                }}
                                                                className="rotate-90 -z-0 text-left w-fit text-[.8rem] -mt-1"
                                                            >
                                                                <MdOutlineSubdirectoryArrowLeft />{' '}
                                                            </div>
                                                            <div className="w-fit text-[.7rem]">
                                                                Replied to
                                                            </div>
                                                        </div>{' '}
                                                        {response?.responses &&
                                                            response?.responses.map(
                                                                (response, index) => (
                                                                    <div className="w-full flex flex-col justify-end items-end">
                                                                        <p className=" w-[50%] self-end text-[.7em] bg-green-100 border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                                            <span className="flex gap-2 mb-2 items-center">
                                                                                <p className="text-main font-bold">
                                                                                    {
                                                                                        response
                                                                                            ?.user
                                                                                            ?.fullname
                                                                                    }
                                                                                </p>
                                                                            </span>
                                                                            <p
                                                                                className="whitespace-break-spaces "
                                                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                                                    response?.answer
                                                                                )}
                                                                            />

                                                                            {response.attachments && (
                                                                                <>
                                                                                    {response.attachments.filter(
                                                                                        (attach) =>
                                                                                            attach.type ===
                                                                                            'image'
                                                                                    ).length >
                                                                                        0 && (
                                                                                        <div className="grid grid-cols-3 gap-2">
                                                                                            {response.attachments
                                                                                                .filter(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) =>
                                                                                                        attach.type ===
                                                                                                        'image'
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            key={
                                                                                                                attach.id
                                                                                                            }
                                                                                                            className="overflow-hidden"
                                                                                                        >
                                                                                                            <img
                                                                                                                alt={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                src={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                className="object-cover w-full h-32"
                                                                                                            />
                                                                                                            <Link
                                                                                                                to={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                target="_blank"
                                                                                                                className="flex mt-1"
                                                                                                            >
                                                                                                                <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                    Click
                                                                                                                    to
                                                                                                                    view
                                                                                                                </p>
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                        </div>
                                                                                    )}

                                                                                    {response.attachments.filter(
                                                                                        (attach) =>
                                                                                            attach.type !==
                                                                                            'image'
                                                                                    ).length >
                                                                                        0 && (
                                                                                        <div>
                                                                                            {response.attachments
                                                                                                .filter(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) =>
                                                                                                        attach.type !==
                                                                                                        'image'
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                            key={
                                                                                                                attach.id
                                                                                                            }
                                                                                                        >
                                                                                                            <img
                                                                                                                alt="PDF Icon"
                                                                                                                className="h-full w-fit"
                                                                                                                src={
                                                                                                                    PDFIcon
                                                                                                                }
                                                                                                            />
                                                                                                            <div className="w-full">
                                                                                                                <p className="font-bold text-darkgrey">
                                                                                                                    Filename:
                                                                                                                </p>
                                                                                                                <Link
                                                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                    to={
                                                                                                                        attach.filename
                                                                                                                    }
                                                                                                                    target="__blank"
                                                                                                                >
                                                                                                                    {
                                                                                                                        attach.filename
                                                                                                                    }
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                )}
                            </>
                        ))}
                    <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                        <span className="flex">MY KIQS </span>
                    </p>
                    {dataContainer?.mykiqs?.questions &&
                        dataContainer?.mykiqs?.questions.map((kiqs_questions, index) => (
                            <>
                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my- w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    {kiqs_questions?.question}
                                </p>
                                {kiqs_questions && kiqs_questions?.responses?.length > 0 && (
                                    <div className="flex flex-col w-full px-6 gap-2">
                                        {kiqs_questions?.responses.map((response) => (
                                            <>
                                                <p className="  w-[65%] self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                    <span className="flex gap-2 mb-2 items-start">
                                                        <p className="text-main font-bold">
                                                            {response?.user?.fullname}
                                                        </p>
                                                    </span>
                                                    <p
                                                        className="whitespace-break-spaces items-end justify-end"
                                                        dangerouslySetInnerHTML={makeUrlsClickable(
                                                            response?.answer
                                                        )}
                                                    />

                                                    {response.attachments && (
                                                        <>
                                                            {response.attachments.filter(
                                                                (attach) => attach.type === 'image'
                                                            ).length > 0 && (
                                                                <div className="grid grid-cols-3 gap-2">
                                                                    {response.attachments
                                                                        .filter(
                                                                            (attach) =>
                                                                                attach.type ===
                                                                                'image'
                                                                        )
                                                                        .map((attach) => (
                                                                            <div
                                                                                key={attach.id}
                                                                                className="overflow-hidden"
                                                                            >
                                                                                <img
                                                                                    alt={
                                                                                        attach.filename
                                                                                    }
                                                                                    src={
                                                                                        attach.filename
                                                                                    }
                                                                                    className="object-cover w-full h-32"
                                                                                />
                                                                                <Link
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="flex mt-1"
                                                                                >
                                                                                    <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                        Click to
                                                                                        view
                                                                                    </p>
                                                                                </Link>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}

                                                            {response.attachments.filter(
                                                                (attach) => attach.type !== 'image'
                                                            ).length > 0 && (
                                                                <div>
                                                                    {response.attachments
                                                                        .filter(
                                                                            (attach) =>
                                                                                attach.type !==
                                                                                'image'
                                                                        )
                                                                        .map((attach) => (
                                                                            <div
                                                                                className="h-28 w-full py-5 flex break-all text-xs"
                                                                                key={attach.id}
                                                                            >
                                                                                <img
                                                                                    alt="PDF Icon"
                                                                                    className="h-full w-fit"
                                                                                    src={PDFIcon}
                                                                                />
                                                                                <div className="w-full">
                                                                                    <p className="font-bold text-darkgrey">
                                                                                        Filename:
                                                                                    </p>
                                                                                    <Link
                                                                                        className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="__blank"
                                                                                    >
                                                                                        {
                                                                                            attach.filename
                                                                                        }
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </p>
                                            </>
                                        ))}
                                    </div>
                                )}
                            </>
                        ))}
                    <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                        <span className="flex">SNAPINTEL </span>
                    </p>
                    {dataContainer?.snapintel?.questions &&
                        dataContainer?.snapintel?.questions.map((snapintel_questions, index) => (
                            <>
                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my- w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    {snapintel_questions?.question}
                                </p>
                                {snapintel_questions &&
                                    snapintel_questions?.responses?.length > 0 && (
                                        <div className="flex flex-col w-full px-6 gap-2">
                                            {snapintel_questions?.responses.map((response) => (
                                                <>
                                                    <p className="  w-[65%] self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                        <span className="flex gap-2 mb-2 items-start">
                                                            <p className="text-main font-bold">
                                                                {response?.user?.fullname}
                                                            </p>
                                                        </span>
                                                        <p
                                                            className="whitespace-break-spaces items-end justify-end"
                                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                                response?.answer
                                                            )}
                                                        />

                                                        {response.attachments && (
                                                            <>
                                                                {response.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type === 'image'
                                                                ).length > 0 && (
                                                                    <div className="grid grid-cols-3 gap-2">
                                                                        {response.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type ===
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    key={attach.id}
                                                                                    className="overflow-hidden"
                                                                                >
                                                                                    <img
                                                                                        alt={
                                                                                            attach.filename
                                                                                        }
                                                                                        src={
                                                                                            attach.filename
                                                                                        }
                                                                                        className="object-cover w-full h-32"
                                                                                    />
                                                                                    <Link
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="flex mt-1"
                                                                                    >
                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                            Click to
                                                                                            view
                                                                                        </p>
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}

                                                                {response.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type !== 'image'
                                                                ).length > 0 && (
                                                                    <div>
                                                                        {response.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type !==
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                    key={attach.id}
                                                                                >
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename:
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </p>
                                                </>
                                            ))}
                                        </div>
                                    )}
                            </>
                        ))}
                </div>
            </PDFExport>
        </div>
    )
}

export default QuestMostRepsExport

import React, { useState, useRef, useEffect } from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

export const DownloadPDFButton = ({
    event,
    eventKey,
    handleDownloadPDF,
    exportPDFConvo,
    exportPDFAllKIQS,
    setExportKIQType,
    setIsDownloadCompany,
    openPopupIndex,
    setOpenPopupIndex,
}) => {
    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenPopupIndex(null) // Close the popup
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div ref={ref} className="flex gap-2 items-center justify-center">
            <button
                onClick={() => handleDownloadPDF(event, eventKey)}
                className="text-xs relative whitespace-nowrap flex items-center gap-2 h-fit cursor-pointer border-main rounded-md border py-2 px-4 my-2"
            >
                PDF
                <span>
                    {' '}
                    <MdOutlineFileDownload />
                </span>
                {openPopupIndex === eventKey && (
                    <div
                        ref={ref}
                        className="animate_fade_up z-0 mt-[5rem] -ml-2 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main"
                    >
                        <button
                            onClick={() => {
                                exportPDFConvo(event, 0)
                                setExportKIQType(0)
                            }}
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                        >
                            Chatroom PDF
                            <button className="text-lg">
                                <MdOutlineFileDownload />
                            </button>
                        </button>
                        <button
                            onClick={() => {
                                exportPDFConvo(event, 1)
                                setExportKIQType(1)
                            }}
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                        >
                            KIQs PDF
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            onClick={() => {
                                exportPDFConvo(event, 2)
                                setExportKIQType(2)
                            }}
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                        >
                            SnapIntel PDF
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() => {
                                exportPDFAllKIQS(event)
                                setIsDownloadCompany(true)
                            }}
                        >
                            Event PDF
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                    </div>
                )}
            </button>
        </div>
    )
}

export const DownloadDocxButton = ({
    event,
    eventKey,
    handleDownloadDOCX,
    selectedEvent,
    openPopupIndexDocx,
    setOpenPopupIndexDocx,
    handleDownloadDocx,
}) => {
    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenPopupIndexDocx(null) // Close the popup
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])
    return (
        <div className="flex gap-2 items-center justify-center">
            <button
                onClick={() => handleDownloadDOCX(event, eventKey)}
                className="text-[10px] relative whitespace-nowrap flex items-center gap-2 h-fit cursor-pointer border-main rounded-md border py-2 px-4 my-2"
            >
                DOCX
                <span className="text-[12px]">
                    <MdOutlineFileDownload />
                </span>
                {openPopupIndexDocx === eventKey && (
                    <div className=" z-50 mt-[4.5rem] -ml-9 absolute flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() => {
                                handleDownloadDocx(
                                    selectedEvent?.event_id,
                                    ['General Discussions'],
                                    'desc'
                                )
                            }}
                        >
                            General Discussions Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2  cursor-pointer"
                            onClick={() => {
                                handleDownloadDocx(
                                    selectedEvent?.event_id,
                                    ['Chatroom KIQs'],
                                    'desc'
                                )
                            }}
                        >
                            Chatroom Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() => {
                                handleDownloadDocx(selectedEvent?.event_id, ['My KIQs'], 'desc')
                            }}
                        >
                            KIQs Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() => {
                                handleDownloadDocx(selectedEvent?.event_id, ['SnapIntel'], 'desc')
                            }}
                        >
                            SnapIntel Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDownloadDocx(
                                    selectedEvent?.event_id,
                                    [
                                        'General Discussions',
                                        'Chatroom KIQs',
                                        'My KIQs',
                                        'SnapIntel',
                                    ],
                                    'desc'
                                )
                            }
                        >
                            Event Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                    </div>
                )}
            </button>
        </div>
    )
}

import React, { useState } from 'react'
import PDFIcon from '../../assets/pdf.png'
import { NavLink } from 'react-router-dom'
import { LuExpand } from 'react-icons/lu'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import ImageView from 'components/ImageView'
import { FaFileDownload } from 'react-icons/fa'
import Papa from 'papaparse'
import { LoadingComponent } from 'components/LoadingComponent'
import { getExportResponses } from 'services/request/Exports'
import { useMutation } from 'react-query'
import { NoData } from 'components/NoData'

function CompaniesGeneralDiscussion({ generalDiscussionToggle, selectedEvent, isLoading }) {
    const [selectedResponse, setSelectedResponse] = useState(null)
    const [selectedImage, setSelectedImage] = useState()
    const { mutate: ExportData } = useMutation(['GetExportApi'], (data) => {
        getExportResponses(data)
    })

    const exportAllData = (event) => {
        const data = {
            event_id: event.event_id,
            question_id: null,
            type: 0,
            event: event,
        }
        ExportData(data)
    }

    if (isLoading) {
        return (
            <div className="w-full h-full justify-center items-center">
                <LoadingComponent
                    className="w-full justify-center items-center"
                    title={'General Discussion'}
                />
            </div>
        )
    }
    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(urlRegex, (url) => (
            <a class="hover:text-blue-600 hover:underline font-bold" href="${url}" target="_blank">
                <br /> <br /> ${url} <br /> <br />
            </a>
        ))

        return { __html: replacedText }
    }

    return (
        <div className="w-3/4 bg-white rounded-md overflow-y-auto min-h-full h-[100%] sm:w-full grow">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className="w-full h-fit border-b flex border-main p-5 pb-4 border-opacity-40">
                <span className="w-full flex flex-col  items-center text-xl text-main font-bold border-l-4 border-main pl-4">
                    <p className="w-full text-left"> GENERAL DISCUSSION</p>
                    <span className="w-full text-left text-lg">{selectedEvent?.event_name}</span>
                </span>
                <p
                    onClick={() => exportAllData(selectedEvent)}
                    className="text-lg flex flex-col justify-between items-center text-main font-bold cursor-pointer hover:text-xl"
                >
                    <FaFileDownload />
                    <p className="text-sm">Download</p>
                </p>
            </div>

            <div className="w-full px-5 py-3">
                {generalDiscussionToggle && generalDiscussionToggle.length > 0 ? (
                    generalDiscussionToggle.map((response, responseIndex) => (
                        <div
                            // onClick={() => handleQuestionSelect(response)}
                            className=" cursor-pointer text-sm border-b p-2"
                            key={responseIndex}
                        >
                            <span className="text-sm font-bold ">{response?.user?.fullname}</span>
                            <div className="text-xs gap-2 flex flex-col w-fit max-w-[70%] justify-start items-start text-left bg-white bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                <p className="text-sm">
                                    {/* {response.answer} */}
                                    <p
                                        className=" whitespace-break-spaces"
                                        dangerouslySetInnerHTML={makeUrlsClickable(response.answer)}
                                    />
                                    {response?.attachments && response?.attachments.length > 0 && (
                                        <React.Fragment>
                                            {response?.attachments.filter(
                                                (attachment) => attachment.type === 'image'
                                            ).length > 1 ? (
                                                <div className="grid grid-cols-4 gap-1">
                                                    {response?.attachments
                                                        .filter(
                                                            (attachment) =>
                                                                attachment.type === 'image'
                                                        )
                                                        .map((attachment) => (
                                                            <div
                                                                className="flex items-start justify-start w-full"
                                                                key={attachment.id}
                                                            >
                                                                <img
                                                                    className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                    src={attachment.filename}
                                                                    alt="Attachment"
                                                                    onClick={() =>
                                                                        setSelectedImage(
                                                                            attachment.filename
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        ))}
                                                </div>
                                            ) : (
                                                response?.attachments
                                                    .filter(
                                                        (attachment) => attachment.type === 'image'
                                                    )
                                                    .map((attachment) => (
                                                        <div
                                                            className="flex items-start justify-start w-full"
                                                            key={attachment.id}
                                                        >
                                                            <img
                                                                className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                src={attachment.filename}
                                                                alt="Attachment"
                                                                onClick={() =>
                                                                    setSelectedImage(
                                                                        attachment.filename
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ))
                                            )}
                                            <div>
                                                {response?.attachments
                                                    .filter(
                                                        (attachment) => attachment.type !== 'image'
                                                    )
                                                    .map((attachment) => (
                                                        <div
                                                            className="flex items-start justify-start w-full"
                                                            key={attachment.id}
                                                        >
                                                            {attachment.type === 'file' ? (
                                                                <NavLink
                                                                    to={attachment.filename}
                                                                    target="__blank"
                                                                    className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                >
                                                                    <img
                                                                        className="w-8"
                                                                        src={PDFIcon}
                                                                        alt="TEXT_FILE"
                                                                    />
                                                                    <p className="text-left text-xs break-all truncate-multiline-2">
                                                                        {attachment.filename}
                                                                    </p>
                                                                </NavLink>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </p>
                                <p className="text-xs">Date Created: {response.created_at}</p>
                            </div>

                            <div className="w-full flex flex-col gap-2">
                                <div className="px-10 flex gap-2">
                                    {response.responses?.length > 0 && (
                                        <div className="flex">
                                            <span className="rotate-90 text-base justify-center items-center ">
                                                <MdOutlineSubdirectoryArrowLeft />
                                            </span>
                                            <span
                                                onClick={() =>
                                                    setSelectedResponse(
                                                        selectedResponse === response
                                                            ? null
                                                            : response
                                                    )
                                                }
                                                className="cursor-pointer "
                                            >
                                                {selectedResponse === response ? 'Hide' : 'Show'}{' '}
                                                Replies
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {selectedResponse === response &&
                                    response.responses?.length > 0 && (
                                        <React.Fragment>
                                            <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                                                {response.responses.map((responses) => (
                                                    <div>
                                                        <span className="text-xs font-bold">
                                                            {responses?.user?.fullname}
                                                        </span>
                                                        <div
                                                            className="text-xs flex flex-col w-fit gap-3 bg-main  bg-opacity-30 py-2 p-4 my-1 rounded-l-md rounded-tr-md shadow-sm"
                                                            key={responses.id}
                                                        >
                                                            {/* {responses.answer} */}
                                                            <p className="text-sm text-justify break-words">
                                                                {responses.answer &&
                                                                    responses.answer
                                                                        .split('\n')
                                                                        .map((line, index) => (
                                                                            <React.Fragment
                                                                                key={index}
                                                                            >
                                                                                {line}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                            </p>

                                                            {responses?.attachments &&
                                                                responses?.attachments.length >
                                                                    0 && (
                                                                    <React.Fragment>
                                                                        {responses?.attachments.filter(
                                                                            (attachment) =>
                                                                                attachment.type ===
                                                                                'image'
                                                                        ).length > 1 ? (
                                                                            <div className="grid grid-cols-4 gap-1">
                                                                                {responses?.attachments
                                                                                    .filter(
                                                                                        (
                                                                                            attachment
                                                                                        ) =>
                                                                                            attachment.type ===
                                                                                            'image'
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            attachment
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex items-start justify-start w-full"
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                                                    src={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    alt="Attachment"
                                                                                                    onClick={() =>
                                                                                                        setSelectedImage(
                                                                                                            attachment.filename
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        ) : (
                                                                            responses?.attachments
                                                                                .filter(
                                                                                    (attachment) =>
                                                                                        attachment.type ===
                                                                                        'image'
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        attachment
                                                                                    ) => (
                                                                                        <div
                                                                                            className="flex items-start justify-start w-full"
                                                                                            key={
                                                                                                attachment.id
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                                src={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                alt="Attachment"
                                                                                                onClick={() =>
                                                                                                    setSelectedImage(
                                                                                                        attachment.filename
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                )
                                                                        )}
                                                                        <div>
                                                                            {responses?.attachments
                                                                                .filter(
                                                                                    (attachment) =>
                                                                                        attachment.type !==
                                                                                        'image'
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        attachment
                                                                                    ) => (
                                                                                        <div
                                                                                            className="flex items-start justify-start w-full"
                                                                                            key={
                                                                                                attachment.id
                                                                                            }
                                                                                        >
                                                                                            {attachment.type ===
                                                                                            'file' ? (
                                                                                                <NavLink
                                                                                                    to={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    target="__blank"
                                                                                                    className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                >
                                                                                                    <img
                                                                                                        className="w-8"
                                                                                                        src={
                                                                                                            PDFIcon
                                                                                                        }
                                                                                                        alt="TEXT_FILE"
                                                                                                    />
                                                                                                    <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                        {
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                    </p>
                                                                                                </NavLink>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                            <span className="text-xs font-bold text-grey">
                                                                <span>Date: </span>{' '}
                                                                {responses.created_at}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    )}
                            </div>
                        </div>
                    ))
                ) : (
                    <NoData />
                )}
            </div>
        </div>
    )
}

export default CompaniesGeneralDiscussion
